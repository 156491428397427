import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingBackendGroups: [],
    };
  },
  computed: {
    ...mapFields("backendGroup", {
      backendGroupIds: "allIds",
      backendGroupIdsCached: "cacheAllIds",
    }),
    ...mapGetters("backendGroup", {
      findBackendGroup: "find",
    }),
  },
  methods: {
    ...mapActions("backendGroup", {retrieveBackendGroup: "load"}),
    resolveBackendGroup(itemId) {
      if (!this.isBackendGroupLoaded(itemId) && !this.loadingBackendGroups.includes(itemId)) {
        this.loadingBackendGroups.push(itemId);
        this.retrieveBackendGroup(decodeURIComponent(itemId));
      }
      return this.findBackendGroup(itemId);
    },
    resolveBackendGroups(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveBackendGroup(itemId);
      }).filter(e => e);
    },
    getBackendGroupItem(backendGroupIri, type) {
      if (backendGroupIri !== null) {
        let backendGroup = this.resolveBackendGroup(backendGroupIri);
        if (backendGroup) {
          return backendGroup[type];
        }
      }
      return '';
    },
    isBackendGroupLoaded(itemId) {
      return this.backendGroupIdsCached.includes(itemId);
    },
    isBackendGroupSetLoaded(itemIds) {
      return itemIds.every(itemId => this.isBackendGroupLoaded(itemId));
    },
    resolveBackendGroupAsync(itemId) {
      const poll = resolve => {
        if (this.isBackendGroupLoaded((itemId))) {
          resolve(this.resolveBackendGroup(itemId));
        } else {
          this.resolveBackendGroup(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    resolveBackendGroupsAsync(itemIds) {
      const poll = resolve => {
        if (this.isBackendGroupSetLoaded((itemIds))) {
          resolve(this.resolveBackendGroups(itemIds));
        } else {
          this.resolveBackendGroup(itemIds);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
  }
};
